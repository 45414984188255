function accordion() {
  jQuery(".accordion-head").click(function (event) {
    event.preventDefault();
    if (jQuery(this).hasClass("open")) {
      jQuery(this).toggleClass("open");
      jQuery(this).next().toggleClass("open");
    } else {
      jQuery(".accordion-head").removeClass("open");
      jQuery(".accordion-content").removeClass("open");
      jQuery(this).toggleClass("open");
      jQuery(this).next().toggleClass("open");
    }
    return false;
  });

  jQuery(".acc-inner-head").click(function (event) {
    event.preventDefault();
    if (jQuery(this).hasClass("open")) {
      jQuery(this).toggleClass("open");
      jQuery(this).next().toggleClass("open");
    } else {
      jQuery(".acc-inner-head").removeClass("open");
      jQuery(".text-content").removeClass("open");
      jQuery(this).toggleClass("open");
      jQuery(this).next().toggleClass("open");
    }
    return false;
  });
}
export { accordion };

function accordionArrowTurn() {
  for (let i = 1; i < 100; i++) {
    jQuery("#heading-" + i).click(function () {
      jQuery("#akk-icon-" + i).toggleClass("accordeon-arrow-turn");
    });
  }
}
export { accordionArrowTurn };
