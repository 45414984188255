function gutenbergColumnClasses() {
    jQuery( '.wp-block-column' ).each(function() {
        if (jQuery(this).attr("style")) {
            var str = jQuery(this).attr("style");
            var str1 = str.replace('flex-basis:', 'col-');
            var str2 = str1.replace('.', '_');
            var col = str2.replace('%', '');
            jQuery(this).removeAttr("style").addClass(col);
        }
    })
}

export { gutenbergColumnClasses };