import jQuery from "jquery";
import "@fancyapps/fancybox";
import "bootstrap";
import { WOW } from "wowjs";
import { lazyload } from "./globals/lazyload";
import { gutenbergColumnClasses } from "./globals/gutenbergColumnClasses";
import { toggleNavigation } from "./layout/navigation";
import { toggleSearch } from "./layout/search";
import { slider } from "./partials/slider";
import { accordion } from "./partials/accordion";
import { accordionArrowTurn } from "./partials/accordion";
import { enableForm } from "./partials/form";
import { testimonials } from "./partials/testimonials";

jQuery(document).ready((_) => {
  lazyload();
  //gutenbergColumnClasses();
  toggleNavigation();
  toggleSearch();
  slider();
  accordion();
  enableForm();
  testimonials();
  accordionArrowTurn();

  new WOW().init();
});
