function enableForm() {
    jQuery('#datenschutz').on('change', function(){
        if ( jQuery(this).prop('checked') ) {
            jQuery('button[type=submit]').attr({
                disabled: false
            });
        } else {
            jQuery('button[type=submit]').attr({
                disabled: true
            });
        }
    });
}

export { enableForm };