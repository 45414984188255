import owlCarousel from 'owl.carousel';

function slider() {
    var owl = jQuery('.slider');
    //var itemCount = jQuery(owl).find('.stage').length;

    owl.owlCarousel({
        //animateOut: 'fadeOut',
        nav: true,
        navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
        mouseDrag: false,
        touchDrag: true,
        loop: true,
        dots: true,
        margin: 70,
        autoplay: false,
        smartSpeed: 750,
        stagePadding: 0,
        items: 1,
        lazyLoad: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            },
            1440: {
                items: 3
            }
        }
    });
}

export { slider };
