function toggleNavigation() {
    jQuery('#nav-burger').click(function (event) {
        event.preventDefault();
        jQuery(this).add('html, #nav-container').toggleClass('open-nav');
    })

    jQuery('#main-nav ul li span').click(function (event) {
        event.preventDefault();
        jQuery(this).toggleClass('open');
        jQuery(this).next().toggleClass('open');
    })

    if (jQuery('#main-nav ul li.submenu ul li').hasClass('active')) {
        jQuery('#main-nav ul li.submenu ul li.active').parent().parent().addClass('active');
    }

    // -- reset navbar-toggle
var $toggle = $('.navbar-toggler'),
$navbar = $('.navbar-collapse');

$toggle.on('click', function () {
$(this).toggleClass('collapsed');
$navbar.toggleClass('show');
$("body").toggleClass("open-nav");
return false;
});

}
export { toggleNavigation };
